import React from "react";
import {
  DELIVERY_DETAIL,
  DATE,
  SPACE,
  SEPARATOR,
  IMMAT,
  TEAM,
  DATE_2,
} from "../utils/string";

const Info = ({ delivery }) => {
  const date = new Date(delivery.scheduleAt).toLocaleDateString("fr-FR", {
    timeZone: "Europe/Paris",
  });

  return (
    <>
      <div className="container info-content hideOnPrint">
        <div className="col-12 pt-2 pb-2">
          <b>{delivery.merchantId}</b> <br />
          <b>{delivery.teamName}</b> <br />
          {delivery.teamAddress}
        </div>
        <div className="col-12 pb-2">
          <b>
            {DELIVERY_DETAIL}
            {date}
          </b>
          {SPACE}
          <br />
          {DATE}
          {delivery.date} {DATE_2} {delivery.hour} <br />
          {IMMAT} {delivery.immat} <br />
          {TEAM}
          {delivery.userName} <br />
          {SEPARATOR} {delivery.companyName} {delivery.companyAddress}
          {SPACE}
          {delivery.siret} {SEPARATOR}
        </div>
      </div>
    </>
  );
};

export default Info;
