import React from "react";
import Info from "../components/Info";
import InfoPrint from "../components/InfoPrint";
import Detail from "../components/Detail";
import Warning from "../components/Warning";
import OrderCardItem from "../components/Card/OrderCardItem";
import OrderTableHeader from "../components/Table/OrderTableHeader";

const CarLetter = ({ delivery }) => {
  return (
    <>
      <Info delivery={delivery} />
      <Detail delivery={delivery} />
      <Warning />
      {delivery.orders
        .sort((a, b) => (a.priority > b.priority ? 1 : -1))
        .map(OrdersCardComponent)}

      {/* PRINT VERSION */}
      <InfoPrint delivery={delivery} />
      <OrderTableHeader orders={delivery.orders} />
    </>
  );
};

const OrdersCardComponent = (order, id) => (
  <OrderCardItem order={order} key={id} />
);

export default CarLetter;
