import React from "react";
import ProductTableItem from "./ProductTableItem";
import ProductTableDeliver from "./ProductTableDeliver";
import ProductTableCollect from "./ProductTableCollect";
import { ORDER_CARD_ETA_SEPARATOR } from "../../utils/string";

const OrderTableItem = ({ order }) => {
  return (
    <>
      <tr>
        <td className="content-body align-center">{order.priority}</td>
        <td className="content-body">
          <b>{order.orderNumber}</b> <br />
          {order.address}
        </td>
        <td className="content-body align-center">
          <div className="hour">
            <b>{order.eta}</b>
          </div>
          {order.startEta}
          {ORDER_CARD_ETA_SEPARATOR}
          {order.endEta}
        </td>
        <td className="content-body">
          {order.customerName} <br />
          {order.customerPhone}
        </td>
        <td className="content-body">
          {order.products.map(ProductsTableItemComponent)}
        </td>
        <td className="content-body align-center">
          {order.products.map(ProductsTableDeliverComponent)}
        </td>
        <td className="content-body align-center">
          {order.products.map(ProductsTableCollectComponent)}
        </td>
        <td className="content-body align-center">{order.productD3E}</td>
      </tr>
    </>
  );
};

const ProductsTableItemComponent = (product, id) => (
  <ProductTableItem product={product} key={id} />
);

const ProductsTableDeliverComponent = (product, id) => (
  <ProductTableDeliver product={product} key={id} />
);

const ProductsTableCollectComponent = (product, id) => (
  <ProductTableCollect product={product} key={id} />
);

export default OrderTableItem;
