// ERROR
export const ERROR_APPOINTMENT_001 = "Cet identifiant n'existe pas";
export const ERROR_APPOINTMENT_002 =
  "Cette livraison n'est pas prévue pour aujourd'hui";
export const ERROR_APPOINTMENT_003 =
  "Le nombre maximum de consultations a été atteint";
export const DEFAULT_ERROR = "Aucune Lettre de Voiture disponible";

// WARNING
export const TIGHT_NOCHE = "Créneau resserré";

// INFO
export const DELIVERY_DETAIL = "Tournée du ";
export const DATE = "Date : ";
export const DATE_2 = " à ";
export const IMMAT = "Immat Véhicule : ";
export const TEAM = "Equipage : ";
export const SEPARATOR = "****";
export const SPACE = " ";
export const TOTAL_TO_DELIVER = "Total de produit à livrer : ";
export const TOTAL_TO_COLLECT = "Total de produit à collecter : ";
export const TOTAL_TO_D3E = "Total de produit à collecter D3E : ";

// DETAIL
export const DELIVERY_PRODUCT = "Produit à livrer";
export const DELIVERY_COLLECT = "Produit à collecter";
export const DELIVERY_D3E = "Produit D3E";

// ORDER CARD
export const ORDER_CARD_TITLE = "Commande : ";
export const ORDER_CARD_ADDRESS = "Adresse";
export const ORDER_CARD_ETA_SEPARATOR = " - ";
export const ORDER_CARD_CLIENT = "Client";
export const ORDER_CARD_DESIGNATION = "Produits";

// PRODUCT CARD
export const PRODUCT_CARD_TO_DELIVER = "A livrer : ";
export const PRODUCT_CARD_TO_COLLECT = "A collecter : ";
export const PRODUCT_CARD_TO_D3E = "D3E : ";

// ORDER TABLE
export const ORDER_TABLE_TITLE = "Commande";
export const ORDER_TABLE_ADDRESS = "Adresse de livraison";
export const ORDER_TABLE_ETA_1 = "ETA";
export const ORDER_TABLE_ETA_2 = "Créneau";
export const ORDER_TABLE_CLIENT = "Client";
export const ORDER_TABLE_DESIGNATION = "Désignation";
export const ORDER_TABLE_TO_DELIVER_1 = "A";
export const ORDER_TABLE_TO_DELIVER_2 = "délivrer";
export const ORDER_TABLE_TO_COLLECT = "collecter";
export const ORDER_TABLE_TOTAL_D3E_1 = "Total";
export const ORDER_TABLE_TO_D3E_2 = "D3E";
export const ORDER_PRODUCT_TO_D3E = "Total D3E : ";
