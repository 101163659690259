import React from "react";

const ProductTableDeliver = ({ product }) => {
  return (
    <>
      {product.toDeliver > 0 ? <>{product.toDeliver}</> : <></>} <br />
    </>
  );
};

export default ProductTableDeliver;
