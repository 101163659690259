import React from "react";
import ProductCardItem from "./ProductCardItem";
import {
  ORDER_CARD_TITLE,
  ORDER_CARD_ADDRESS,
  ORDER_CARD_CLIENT,
  ORDER_CARD_DESIGNATION,
  ORDER_CARD_ETA_SEPARATOR,
  ORDER_PRODUCT_TO_D3E,
  SPACE,
} from "../../utils/string";

const OrderCardItem = ({ order }) => {
  return (
    <>
      <div className="container hideOnPrint">
        <div className="col-12 pb-2">
          <div className="card w-100 mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-12 text-center pb-1">
                  <h5 className="card-title">
                    {ORDER_CARD_TITLE}
                    <b>{order.orderNumber}</b>
                  </h5>
                </div>
                <hr />
              </div>
              <div className="row pb-2">
                <div className="col-8">
                  <h5 className="card-title">{ORDER_CARD_ADDRESS}</h5>
                  <p className="card-text">{order.address}</p>
                </div>
                <div
                  className={
                    "col-4 pb-1 text-center " +
                    (order.tightNiche ? "bg-warning" : "")
                  }
                >
                  <div className="hour">
                    <b>{order.eta}</b>
                    <div className="hour-second">
                      {order.startEta}
                      {ORDER_CARD_ETA_SEPARATOR}
                      {order.endEta}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pb-2">
                <div className="col-12">
                  <h5 className="card-title">{ORDER_CARD_CLIENT}</h5>
                  <p className="card-text">
                    {order.customerName}
                    {SPACE}
                    {order.customerPhone}
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row">
                <h5 className="card-title mr-2">{ORDER_CARD_DESIGNATION}</h5>
                <div>
                  <span className="badge badge-total-content rounded-pill text-bg-danger">
                    {ORDER_PRODUCT_TO_D3E}
                    {order.productD3E}
                  </span>
                </div>
              </div>
              {order.products.map(ProductsCardComponent)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ProductsCardComponent = (product, id) => (
  <ProductCardItem product={product} key={id} />
);

export default OrderCardItem;
