import React from "react";

const ProductTableCollect = ({ product }) => {
  return (
    <>
      {product.toCollect > 0 ? <>{product.toCollect}</> : <></>} <br />
    </>
  );
};

export default ProductTableCollect;
