import React from "react";
import Truck from "../style/assets/truck.png";
import {
  DEFAULT_ERROR,
  ERROR_APPOINTMENT_001,
  ERROR_APPOINTMENT_002,
  ERROR_APPOINTMENT_003,
} from "../utils/string";

const Empty = ({ error }) => {
  return (
    <div className="container empty-text">
      <div className="text-center pb-5">
        <img src={Truck} alt="truck" width="200" />
      </div>
      <div className="text-center">
        <div className="col-12">
          {(() => {
            switch (error) {
              case "APPOINTMENT_001": {
                return ERROR_APPOINTMENT_001;
              }

              case "APPOINTMENT_002": {
                return ERROR_APPOINTMENT_002;
              }

              case "APPOINTMENT_003": {
                return ERROR_APPOINTMENT_003;
              }

              default: {
                return DEFAULT_ERROR;
              }
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default Empty;
