import { configureStore } from "@reduxjs/toolkit";
import { getDelivery } from "./service";

export const ActionTypes = {
  GET_DELIVERY: "GET_DELIVERY",
};

const ApplicationState = {
  delivery: null,
  error: null,
};

const middleware = (store) => (next) => (action) => {
  if (action.type === ActionTypes.GET_DELIVERY) {
    getDelivery(action.payload.token).then((response) => {
      if (response !== null) {
        if (response.status === 200) {
          ApplicationState.delivery = response.data;
          store.dispatch({ type: ActionTypes.GET_DELIVERY, payload: response });
        } else {
          ApplicationState.error = response.data.errors[0].code;
          store.dispatch({ type: ActionTypes.GET_DELIVERY, payload: response });
        }
      }
    });
  }

  next(action);
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.GET_DELIVERY:
      return {
        ...state,
        processing: { ...state.processing },
        delivery: ApplicationState.delivery,
        error: ApplicationState.error,
      };
    default:
      return state;
  }
};

export const store = configureStore({
  preloadedState: ApplicationState,
  reducer: reducer,
  middleware: [middleware],
  devTools: true,
});
