import React from "react";
import OrderTableItem from "./OrderTableItem";
import {
  ORDER_TABLE_TITLE,
  ORDER_TABLE_ADDRESS,
  ORDER_TABLE_ETA_2,
  ORDER_TABLE_ETA_1,
  ORDER_TABLE_CLIENT,
  ORDER_TABLE_DESIGNATION,
  ORDER_TABLE_TO_DELIVER_1,
  ORDER_TABLE_TO_DELIVER_2,
  ORDER_TABLE_TO_COLLECT,
  ORDER_TABLE_TOTAL_D3E_1,
  ORDER_TABLE_TO_D3E_2,
} from "../../utils/string";

const OrderTableHeader = ({ orders }) => {
  return (
    <>
      <table className="table-content">
        <tbody>
          <tr>
            <th className="content-header content-header-line"></th>
            <th className="content-header content-header-order">
              {ORDER_TABLE_TITLE} <br />
              {ORDER_TABLE_ADDRESS}
            </th>
            <th className="content-header content-header-eta">
              {ORDER_TABLE_ETA_1} <br />
              {ORDER_TABLE_ETA_2}
            </th>
            <th className="content-header content-header-client">
              {ORDER_TABLE_CLIENT}
            </th>
            <th className="content-header">{ORDER_TABLE_DESIGNATION}</th>
            <th className="content-header content-header-other">
              {ORDER_TABLE_TO_DELIVER_1} <br />
              {ORDER_TABLE_TO_DELIVER_2}
            </th>
            <th className="content-header content-header-other">
              {ORDER_TABLE_TO_DELIVER_1} <br />
              {ORDER_TABLE_TO_COLLECT}
            </th>
            <th className="content-header content-header-other">
              {ORDER_TABLE_TOTAL_D3E_1} <br />
              {ORDER_TABLE_TO_D3E_2}
            </th>
          </tr>
          {orders
            .sort((a, b) => (a.priority > b.priority ? 1 : -1))
            .map(OrdersTableComponent)}
        </tbody>
      </table>
    </>
  );
};

const OrdersTableComponent = (order, id) => (
  <OrderTableItem order={order} key={id} />
);

export default OrderTableHeader;
