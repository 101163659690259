import React from "react";
import Logo from "../style/logo/boulanger.png";

function Header() {
  return (
    <>
      <nav className="navbar fixed-top bg-dark pt-4 pb-4">
        <div className="container">
          <div className="navbar-brand">
            <img src={Logo} alt="logo" width="150" />
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
