import React from "react";
import Yellow from "../style/assets/yellow-card.jpg";
import WarningLogo from "../style/assets/warning.svg";
import { TIGHT_NOCHE } from "../utils/string";

const Warning = () => {
  return (
    <>
      <div className="container hideOnPrint">
        <div className="row">
          <div className="col-12">
            <div className="alert alert-warning pt-1 pb-1" role="alert">
              <div>
                <img
                  className="warning"
                  alt="warning"
                  src={WarningLogo}
                  width="25"
                />
                {TIGHT_NOCHE}
                <img
                  className="yellow-card"
                  alt="yellow-card"
                  src={Yellow}
                  width="20"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Warning;
