import React from "react";
import {
  DELIVERY_COLLECT,
  DELIVERY_D3E,
  DELIVERY_PRODUCT,
} from "../utils/string";

const Detail = ({ delivery }) => {
  return (
    <>
      <div className="container-fluid bg-light pt-4 pb-3 mb-3 hideOnPrint">
        <div className="row">
          <div className="col-4 text-primary text-center">
            <h5 className="detail-title">{DELIVERY_PRODUCT}</h5>
            <hr style={{ margin: "auto" }} width="100" />
            <h5 className="pt-2">{delivery.totalDeliver}</h5>
          </div>
          <div className="col-4 text-success text-center">
            <h5 className="detail-title">{DELIVERY_COLLECT}</h5>
            <hr style={{ margin: "auto" }} width="100" />
            <h5 className="pt-2">{delivery.totalCollect}</h5>
          </div>
          <div className="col-4 text-danger text-center">
            <h5 className="detail-title">{DELIVERY_D3E}</h5>
            <hr style={{ margin: "auto" }} width="100" />
            <h5 className="pt-2">{delivery.totalD3E}</h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
