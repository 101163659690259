export const getDelivery = (token = "") => {
  if (!token) return Promise.resolve(null);

  const options = {
    method: "GET",
  };

  let status;

  return fetch(process.env.REACT_APP_GET_API_APPOINTMENT + token, options)
    .then((response) => {
      status = response.status;
      return response.json();
    })
    .then((data) => {
      if (data) {
        return {
          data: data,
          status: status,
        };
      } else {
        return null;
      }
    });
};
