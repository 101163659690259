import React from "react";
import {
  DELIVERY_DETAIL,
  DATE,
  SPACE,
  SEPARATOR,
  IMMAT,
  TEAM,
  DATE_2,
  TOTAL_TO_DELIVER,
  TOTAL_TO_COLLECT,
  TOTAL_TO_D3E,
} from "../utils/string";

const InfoPrint = ({ delivery }) => {
  const date = new Date(delivery.scheduleAt).toLocaleDateString("fr-FR", {
    timeZone: "Europe/Paris",
  });

  return (
    <>
      <div className="container-fluid header-content pb-3">
        <div className="col-12 pb-2">
          <b>{delivery.merchantId}</b> <br />
          <b>{delivery.teamName}</b> <br />
          {delivery.teamAddress}
        </div>
        <div className="row align-items-end">
          <div className="col-8">
            <b>
              {DELIVERY_DETAIL}
              {date}
            </b>
            {SPACE}
            <br />
            {DATE}
            {delivery.date} {DATE_2} {delivery.hour} <br />
            {IMMAT} {delivery.immat} <br />
            {TEAM}
            {delivery.userName} <br />
            {SEPARATOR} {delivery.companyName} {delivery.companyAddress},{SPACE}
            {delivery.siret} {SEPARATOR}
          </div>
          <div className="col-4">
            {TOTAL_TO_DELIVER} {delivery.totalDeliver}
            <br />
            {TOTAL_TO_COLLECT} {delivery.totalCollect}
            <br />
            {TOTAL_TO_D3E} {delivery.totalD3E}
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoPrint;
