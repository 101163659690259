import React from "react";
import CarLetter from "../features/CarLetter";
import Empty from "../features/Empty";
import { ActionTypes, store } from "../core";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import { mock } from "../utils/mock";

const Home = () => {
  const id = new URLSearchParams(useLocation().search).get("id");
  const [appState, setAppState] = React.useState(store.getState());

  React.useEffect(() => {
    process.env.REACT_APP_MOCK === "true" && id === mock.delivery.uuid
      ? setAppState(mock)
      : store.dispatch({
          type: ActionTypes.GET_DELIVERY,
          payload: { token: id },
        });
  }, [id]);

  React.useEffect(() => {
    store.subscribe(() => {
      setAppState(store.getState());
    });
  }, []);

  return (
    <div className="App">
      <Header />
      {appState.delivery != null ? (
        <CarLetter delivery={appState.delivery} />
      ) : (
        <Empty error={appState.error} />
      )}
    </div>
  );
};

export default Home;
