import React from "react";
import {
  PRODUCT_CARD_TO_DELIVER,
  PRODUCT_CARD_TO_COLLECT,
  PRODUCT_CARD_TO_D3E,
} from "../../utils/string";

const ProductCardItem = ({ product }) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <p className="card-text">{product.name}</p>
        </div>
        <div className="col-auto">
          <div>
            {product.toDeliver > 0 ? (
              <span
                className={
                  "badge badge-content rounded-pill " +
                  (product.tos ? "text-bg-secondary" : "text-bg-primary")
                }
              >
                {PRODUCT_CARD_TO_DELIVER}
                {product.toDeliver}
              </span>
            ) : (
              <></>
            )}
            {product.toCollect > 0 ? (
              <span className="badge badge-content rounded-pill text-bg-success">
                {PRODUCT_CARD_TO_COLLECT}
                {product.toCollect}
              </span>
            ) : (
              <></>
            )}
            {product.toD3E > 0 ? (
              <span className="badge badge-content rounded-pill text-bg-danger">
                {PRODUCT_CARD_TO_D3E}
                {product.toD3E}
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCardItem;
