import React from "react";

const ProductTableItem = ({ product }) => {
  return (
    <>
      {product.name} <br />
    </>
  );
};

export default ProductTableItem;
